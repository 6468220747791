import React, { useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import moment from 'moment';
import { animated, useTransition } from 'react-spring/web.cjs';

import AnimatedWorkoutHeader from './AnimatedWorkoutHeader';
import Spinner from './Spinner';
import WeightroomPanelHeader from '../container/WeightroomPanelHeader';
import WeightroomWorkoutList from '../container/WeightroomWorkoutList';
import Complete from './Complete';
import UnlockWorkoutsAlert from './UnlockWorkoutsAlert';
import RecoveryRadarButton from '../../../recovery-radar-tracker/components/presentational/RecoveryRadarButton';
import {
  fetchBodyMapData,
  setIsRecoveryRadarWeightroomModalShowing,
  setActiveWeightroomPanel,
} from '../../../recovery-radar-tracker/ducks/recoveryRadarTrackerActions';

export const SpinnerContainer = styled('div')`
  display: flex;
  height: 100%;
  /* ${(props) => (
    props.activeScreens === 4
      ? 'height: calc(50vh - 137px);'
      : 'height: calc(100vh - 137px);'
  )} */
  flex: 1;
  position: relative;

  /* @media screen and (orientation: portrait) {
    ${(props) => (
    props.activeScreens <= 2 && `
      height: calc(50vh - 137px);
    `
  )}
  }

  @media screen and (orientation: landscape) {
    ${(props) => (
    props.activeScreens <= 2 && `
      height: calc(100vh - 137px);
    `
  )}
  } */
`;

const PanelWorkouts = ({
  activeScreens,
  closePanel,
  closeWorkout,
  isLoading,
  isWorkoutComplete,
  namespace,
}) => {
  const dispatch = useDispatch();

  const activeWeightroomPanel = useSelector(
    (state) => state.recoveryRadarTracker.data.activeWeightroomPanel,
  );
  const timezone = useSelector(
    (state) => state.weightRoomView.data.currentUser.userSettings.timeZone,
  );
  const currentWeightroomUser = useSelector((state) => state.weightRoomView.data.currentUser);
  const activeAthleteId = useSelector((state) => state.weightRoomView[namespace].athleteId);
  const activeDate = useSelector(
    (state) => state.weightRoomView[namespace].activeDate,
  );
  const ssHubEnabled = currentWeightroomUser?.userOrgSSHubSettings?.ssHubEnabled;

  const parsedActiveDate = activeDate ? moment(activeDate) : null;
  const today = moment().tz(timezone);
  const isWithinSevenDays = parsedActiveDate
    ? parsedActiveDate.isSameOrBefore(today, 'day')
      && today.diff(parsedActiveDate, 'days') <= 7
    : false;
  const formattedActiveDate = parsedActiveDate
    ? parsedActiveDate.format('YYYY-MM-DD')
    : null;

  useEffect(() => {
    const isFutureDate = moment(formattedActiveDate).isAfter(moment(), 'day');
    if (
      namespace === activeWeightroomPanel
      && activeAthleteId
      && formattedActiveDate
      && !isFutureDate
    ) {
      dispatch(fetchBodyMapData(
        currentWeightroomUser,
        activeAthleteId,
        formattedActiveDate,
        true,
        namespace,
      ));
    }
  }, [namespace, activeWeightroomPanel, formattedActiveDate]);

  const handleClosePanel = () => {
    closePanel(namespace);
  };

  const handleCloseWorkout = () => {
    closeWorkout(namespace);
  };

  const completeTransition = useTransition(isWorkoutComplete, null, {
    from: { opacity: 0, height: '100%' },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const handleRecoveryRadarClick = () => {
    dispatch(setActiveWeightroomPanel(namespace));
    dispatch(setIsRecoveryRadarWeightroomModalShowing(true));
  };

  return (
    <>
      <WeightroomPanelHeader
        namespace={namespace}
      />

      <AnimatedWorkoutHeader
        namespace={namespace}
      />
      {activeAthleteId && isWithinSevenDays && ssHubEnabled && (
        <RecoveryRadarButton context='weightRoomView' handleClick={handleRecoveryRadarClick} namespace={namespace} />
      )}
      {isLoading ? (
        <>
          <SpinnerContainer
            activeScreens={activeScreens}
          >
            <Spinner />
          </SpinnerContainer>
        </>
      ) : (
        <WeightroomWorkoutList
          namespace={namespace}
        />
      )}
      <UnlockWorkoutsAlert namespace={namespace} />
      {completeTransition.map(({ item, key, props }) => (
        item && (
          <animated.div key={key} style={props}>
            <Complete
              handleCloseWorkout={handleCloseWorkout}
              handleClosePanel={handleClosePanel}
            />
          </animated.div>
        )
      ))}
    </>
  );
};

PanelWorkouts.propTypes = {
  activeScreens: PropTypes.number.isRequired,
  closePanel: PropTypes.instanceOf(Function).isRequired,
  closeWorkout: PropTypes.instanceOf(Function).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isWorkoutComplete: PropTypes.bool.isRequired,
  namespace: PropTypes.string.isRequired,
};

export default memo(PanelWorkouts);
