import axios from 'axios';
import axiosCancel from 'axios-cancel';
import moment from 'moment';
import groupBy from 'lodash.groupby';

import {
  SELECT_ATHLETE,
  SELECT_VERIFIED_ATHLETE,
  FETCH_WORKOUTS_FULFILLED,
  FETCH_WORKOUTS_REJECTED,
  FETCH_WORKOUTS_START,
  SELECT_WORKOUT,
  SEARCH_ATHLETE,
  CLOSE_PANEL,
  SELECT_DATE,
  NEXT_WORKOUT,
  PREV_WORKOUT,
  CLOSE_WORKOUT,
  COMPLETE_WORKOUT,
  OPEN_MODAL,
  CLOSE_MODAL,
  FETCH_HISTORY_START,
  FETCH_HISTORY_FULFILLED,
  FETCH_HISTORY_REJECTED,
  FETCH_DOCUMENT_START,
  FETCH_DOCUMENT_REJECTED,
  FETCH_DOCUMENT_FULFILLED,
  FETCH_EXERCISES_START,
  FETCH_EXERCISES_FULFILLED,
  FETCH_EXERCISES_REJECTED,
  FETCH_SIM_EXERCISES_START,
  FETCH_SIM_EXERCISES_FULFILLED,
  FETCH_SIM_EXERCISES_REJECTED,
  FETCH_SIM_TYPE_EXERCISES_START,
  FETCH_SIM_TYPE_EXERCISES_FULFILLED,
  FETCH_SIM_TYPE_EXERCISES_REJECTED,
  UPDATE_WORKOUTS_START,
  UPDATE_WORKOUTS_FULFILLED,
  UPDATE_WORKOUTS_REJECTED,
  UPDATE_SUBSTITUTE_WORKOUT,
  SELECT_QUESTION,
  NEXT_QUESTION,
  PREV_QUESTION,
  RESTART_QUESTIONS,
  UPDATE_JOURNAL_START,
  UPDATE_JOURNAL_FULFILLED,
  UPDATE_JOURNAL_REJECTED,
  STORE_PANEL_HEIGHT,
  FETCH_CALENDAR_START,
  FETCH_CALENDAR_FULFILLED,
  FETCH_CALENDAR_REJECTED,
  SUBMIT_PIN_START,
  SUBMIT_PIN_FULFILLED,
  SUBMIT_PIN_REJECTED,
  OPEN_PINPAD,
  CLOSE_PINPAD,
  CANCEL_PINPAD,
  STORE_FORMIK_VALUES,
  STORE_WORKOUTS_BY_SUPERSET,
  INCREASE_COMPLETED_SETS,
  DECREASE_COMPLETED_SETS,
  SET_IS_NAVIGATING_SUPERSETS,
  SET_ADDITIONAL_WORKOUTS_DATA,
  CLEAR_ADDITIONAL_WORKOUTS_DATA,
  SET_IS_ADDITIONAL_WORKOUTS,
  SET_SHOW_SESSION_BREAK_ITEMS,
  SET_IS_CALENDAR_PROGRAM_VIEW,
  SET_IS_PROGRAM_LIST_MODAL,
  SET_IS_RESET_PROGRAM_MODAL_SHOWING,
  SET_IS_PROGRAM_INFO_MODAL_SHOWING,
  SET_IS_COMPLETE_PROGRAM_MODAL_SHOWING,
  SET_IS_COMPLETE_PROGRAM_WORKOUT_MODAL_SHOWING,
  SET_IS_SKIP_WORKOUT_MODAL_SHOWING,
  CLEAR_PROGRAM_DATA,
  UPDATE_SUBSTITUTE_PROGRAM_WORKOUT,
  FETCH_PROGRAMS_START,
  FETCH_PROGRAMS_FULFILLED,
  FETCH_PROGRAMS_REJECTED,
  FETCH_PROGRAM_WORKOUTS_START,
  FETCH_PROGRAM_WORKOUTS_FULFILLED,
  FETCH_PROGRAM_WORKOUTS_REJECTED,
  FETCH_NEXT_WORKOUT_SESSION_START,
  FETCH_NEXT_WORKOUT_SESSION_FULFILLED,
  FETCH_NEXT_WORKOUT_SESSION_REJECTED,
  FETCH_PROGRAM_WEEKS_START,
  FETCH_PROGRAM_WEEKS_FULFILLED,
  FETCH_PROGRAM_WEEKS_REJECTED,
  FETCH_PROGRAM_WEEK_START,
  FETCH_PROGRAM_WEEK_FULFILLED,
  FETCH_PROGRAM_WEEK_REJECTED,
  RESET_PROGRAM_START,
  RESET_PROGRAM_FULFILLED,
  RESET_PROGRAM_REJECTED,
  COMPLETE_PROGRAM_START,
  COMPLETE_PROGRAM_FULFILLED,
  COMPLETE_PROGRAM_REJECTED,
  FINISH_PROGRAM_WORKOUT_START,
  FINISH_PROGRAM_WORKOUT_FULFILLED,
  FINISH_PROGRAM_WORKOUT_REJECTED,
  UPDATE_PROGRAM_WORKOUT_START,
  UPDATE_PROGRAM_WORKOUT_FULFILLED,
  UPDATE_PROGRAM_WORKOUT_REJECTED,
  FETCH_PROGRAM_WEEK_VIA_NEXT_SESSION_START,
  FETCH_PROGRAM_WEEK_VIA_NEXT_SESSION_FULFILLED,
  FETCH_PROGRAM_WEEK_VIA_NEXT_SESSION_REJECTED,
  FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_FULFILLED,
  FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_REJECTED,
  FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_START,
  SET_SELECTED_INTEGRATION,
  FETCH_SELECTED_INTEGRATION_BY_DATE_START,
  FETCH_SELECTED_INTEGRATION_BY_DATE_FULFILLED,
  FETCH_SELECTED_INTEGRATION_BY_DATE_REJECTED,
  SET_IS_INTEGRATION_MODAL_SHOWING,
  FETCH_MORE_HISTORY_START,
  FETCH_MORE_HISTORY_FULFILLED,
  FETCH_MORE_HISTORY_REJECTED,
} from './actionTypes';

import { axiosWRV } from '../../../shared/utils/setCommonHeaders';

axiosCancel(axios, {
  debug: false, // default
});

export const fetchWorkoutsFulfilled = (workout, namespace) => (
  (dispatch) => {
    dispatch({
      type: `${namespace}/${FETCH_WORKOUTS_FULFILLED}`,
      payload: workout,
    });
  }
);

export const fetchWorkouts = (accountCode, id, namespace, date, isAdditionalWorkouts = false) => (
  (dispatch) => {
    const formattedDate = date.format('YYYY-MM-DD');
    dispatch({ type: `${namespace}/${FETCH_WORKOUTS_START}`, payload: isAdditionalWorkouts });
    // ?organizeBySession=true query param for collapsible session breaks
    axiosWRV.get(`/accounts/${accountCode}/users/${id}/workouts/${formattedDate}`, {
      requestId: `${namespace}/fetchWorkouts`,
    })
      .then((response) => {
        setTimeout(() => {
          dispatch({ type: `${namespace}/${FETCH_WORKOUTS_FULFILLED}`, payload: response.data });
        }, 800);

        let workoutsWithSupersets = response.data.workoutItems.filter((workout) => (
          workout.groupingLetter !== null
        ));

        if (workoutsWithSupersets.length > 0) {
          workoutsWithSupersets = workoutsWithSupersets.map((workout) => ({
            ...workout, completed_sets: 0,
          }));

          const workoutsBySupersets = groupBy(workoutsWithSupersets, 'groupingLetter');

          dispatch({
            type: `${namespace}/${STORE_WORKOUTS_BY_SUPERSET}`,
            payload: workoutsBySupersets,
          });
        }
      })
      .catch((thrown) => {
        if (!axios.isCancel(thrown)) {
          dispatch({ type: `${namespace}/${FETCH_WORKOUTS_REJECTED}`, payload: thrown.response });
        }
      });
  }
);

export const fetchWorkoutCalendar = (accountCode, athleteId, namespace, startDate, endDate) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${FETCH_CALENDAR_START}` });
    axiosWRV.get(`/accounts/${accountCode}/users/${athleteId}/workouts/overview?dateRangeStart=${startDate}&dateRangeEnd=${endDate}`, {
      requestId: `${namespace}/fetchWorkoutCalendar`,
    })
      .then((response) => {
        dispatch({ type: `${namespace}/${FETCH_CALENDAR_FULFILLED}`, payload: response.data });
      })
      .catch((thrown) => {
        if (axios.isCancel(thrown)) {
          console.log('Fetch Calendar Cancelled');
        } else {
          console.log('Other Reason');
        }
      })
      .catch((err) => {
        dispatch({ type: `${namespace}/${FETCH_CALENDAR_REJECTED}`, payload: err });
      });
  }
);

export const selectAthlete = (accountCode, athlete, id, avatar, namespace) => (
  (dispatch, getState) => {
    const today = moment().tz(getState().weightRoomView.data.currentUser.userSettings.timeZone);
    dispatch({
      type: `${namespace}/${SELECT_ATHLETE}`,
      payload: {
        athlete, id, avatar, namespace, today,
      },
    });
    dispatch(fetchWorkouts(accountCode, id, namespace, today));
  }
);

export const closePanel = (namespace) => (
  (dispatch) => {
    dispatch({
      type: `${namespace}/${CLOSE_PANEL}`,
      payload: {
        namespace,
      },
    });
    dispatch({ type: `${namespace}/${CLEAR_PROGRAM_DATA}` });
  }
);

export const closeWorkout = (namespace) => (
  (dispatch) => {
    dispatch({
      type: `${namespace}/${CLOSE_WORKOUT}`,
      payload: {
        namespace,
      },
    });
  }
);

export const selectWorkout = (id, index, exerId, exerType, namespace) => (
  (dispatch) => {
    dispatch({
      type: `${namespace}/${SELECT_WORKOUT}`,
      payload: {
        id, index, exerId, exerType,
      },
    });
  }
);

export const nextWorkout = (id, index, exerId, exerType, namespace) => (
  (dispatch) => {
    dispatch({
      type: `${namespace}/${NEXT_WORKOUT}`,
      payload: {
        id, index, exerId, exerType,
      },
    });
  }
);

export const prevWorkout = (id, index, exerId, exerType, namespace) => (
  (dispatch) => {
    dispatch({
      type: `${namespace}/${PREV_WORKOUT}`,
      payload: {
        id, index, exerId, exerType,
      },
    });
  }
);

export const selectDate = (accountCode, date, id, namespace) => (
  (dispatch) => {
    dispatch({
      type: `${namespace}/${SELECT_DATE}`,
      payload: date,
    });
    dispatch(fetchWorkouts(accountCode, id, namespace, date));
  }
);

export const searchAthlete = (athlete, namespace) => (
  (dispatch) => {
    dispatch({
      type: `${namespace}/${SEARCH_ATHLETE}`,
      payload: athlete,
    });
  }
);

export const openModal = (namespace, modal) => ({
  type: `${namespace}/${OPEN_MODAL}`,
  payload: modal,
});

export const closeModal = (namespace) => ({
  type: `${namespace}/${CLOSE_MODAL}`,
});

export const openPinPad = (name, id, avatar, namespace) => (
  (dispatch, getState) => {
    const today = moment().tz(getState().weightRoomView.data.currentUser.userSettings.timeZone);
    dispatch({
      type: `${namespace}/${OPEN_PINPAD}`,
      payload: {
        today,
        name,
        id,
        avatar,
      },
    });
  }
);

export const completeWorkout = (namespace) => ({
  type: `${namespace}/${COMPLETE_WORKOUT}`,
});

export const closePinPad = (namespace) => ({
  type: `${namespace}/${CLOSE_PINPAD}`,
});

export const cancelPinPad = (namespace) => ({
  type: `${namespace}/${CANCEL_PINPAD}`,
});

export const selectVerifiedAthlete = (namespace) => ({
  type: `${namespace}/${SELECT_VERIFIED_ATHLETE}`,
});

export const fetchHistory = (namespace, accountCode, exerId, userId) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${FETCH_HISTORY_START}` });
    axiosWRV.get(`/accounts/${accountCode}/users/${userId}/exercises/${exerId}/workouts/history`)
      .then((response) => {
        dispatch({ type: `${namespace}/${FETCH_HISTORY_FULFILLED}`, payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: `${namespace}/${FETCH_HISTORY_REJECTED}`, payload: err });
      });
  }
);

export const fetchMoreHistory = (namespace, accountCode, exerId, userId, currentPage) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${FETCH_MORE_HISTORY_START}` });
    axiosWRV.get(`/accounts/${accountCode}/users/${userId}/exercises/${exerId}/workouts/history?page=${currentPage + 1}`)
      .then((response) => {
        setTimeout(() => {
          dispatch({ type: `${namespace}/${FETCH_MORE_HISTORY_FULFILLED}`, payload: response.data });
        }, 1000);
      })
      .catch((err) => {
        dispatch({ type: `${namespace}/${FETCH_MORE_HISTORY_REJECTED}`, payload: err });
      });
  }
);

export const fetchDocument = (namespace, accountCode, docId) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${FETCH_DOCUMENT_START}` });
    axiosWRV.get(`accounts/${accountCode}/documents/${docId}`)
      .then((response) => {
        dispatch({ type: `${namespace}/${FETCH_DOCUMENT_FULFILLED}`, payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: `${namespace}/${FETCH_DOCUMENT_REJECTED}`, payload: err });
      });
  }
);

export const fetchExercises = (namespace, accountCode) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${FETCH_EXERCISES_START}` });
    axiosWRV.get(`/accounts/${accountCode}/exercises?type=L`)
      .then((response) => {
        dispatch({ type: `${namespace}/${FETCH_EXERCISES_FULFILLED}`, payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: `${namespace}/${FETCH_EXERCISES_REJECTED}`, payload: err });
      });
  }
);

export const fetchSimilarExercises = (namespace, accountCode, exerId) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${FETCH_SIM_EXERCISES_START}` });
    axiosWRV.get(`/accounts/${accountCode}/exercises?similarTo=${exerId}`)
      .then((response) => {
        dispatch({ type: `${namespace}/${FETCH_SIM_EXERCISES_FULFILLED}`, payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: `${namespace}/${FETCH_SIM_EXERCISES_REJECTED}`, payload: err.response.headers.message });
      });
  }
);

export const fetchSimilarTypeExercises = (namespace, accountCode, tagId) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${FETCH_SIM_TYPE_EXERCISES_START}` });
    axiosWRV.get(`accounts/${accountCode}/exercises?tagId=${tagId}`)
      .then((response) => {
        dispatch({ type: `${namespace}/${FETCH_SIM_TYPE_EXERCISES_FULFILLED}`, payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: `${namespace}/${FETCH_SIM_TYPE_EXERCISES_REJECTED}`, payload: err.response.headers.message });
      });
  }
);

export const updateWorkout = (index, namespace, values) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${UPDATE_WORKOUTS_START}` });
    axiosWRV.post(`/accounts/${values.accountCode}/users/${values.userId}/workouts/${values.assignedDate}/items/${values.assignedId}`, values.body, {
      requestId: `${namespace}/updateWorkout`,
    })
      .then((response) => {
        setTimeout(() => {
          dispatch({
            type: `${namespace}/${UPDATE_WORKOUTS_FULFILLED}`,
            payload: {
              index,
              data: response.data,
            },
          });
        }, 800);
      })
      .catch((err) => {
        dispatch({ type: `${namespace}/${UPDATE_WORKOUTS_REJECTED}`, payload: err });
      });
  }
);

export const submitJournal = (index, accountCode, namespace, values) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${UPDATE_JOURNAL_START}` });
    axiosWRV.post(`/accounts/${accountCode}/journals/entries`, values, {
      requestId: `${namespace}/submitJournal`,
    })
      .then((response) => {
        dispatch({
          type: `${namespace}/${UPDATE_JOURNAL_FULFILLED}`,
          payload: {
            index,
            data: response.data,
          },
        });
      })
      .catch((err) => {
        dispatch({ type: `${namespace}/${UPDATE_JOURNAL_REJECTED}`, payload: err });
      });
  }
);

export const updateJournal = (index, accountCode, namespace, values, entryId) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${UPDATE_JOURNAL_START}` });
    axiosWRV.put(`/accounts/${accountCode}/journals/entries/${entryId}`, values, {
      requestId: `${namespace}/updateJournal`,
    })
      .then((response) => {
        dispatch({
          type: `${namespace}/${UPDATE_JOURNAL_FULFILLED}`,
          payload: {
            index,
            data: response.data,
          },
        });
      })
      .catch((err) => {
        dispatch({ type: `${namespace}/${UPDATE_JOURNAL_REJECTED}`, payload: err });
      });
  }
);

export const optOutWorkout = (namespace, accountCode, values) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${UPDATE_WORKOUTS_START}` });
    axiosWRV.post(`/accounts/${accountCode}/users/${values.userId}/workouts/${values.assignedDate}/items/${values.workId}/opt-out`, values.body, {
      requestId: `${namespace}/optOutWorkout`,
    })
      .then((response) => {
        dispatch({
          type: `${namespace}/${UPDATE_SUBSTITUTE_WORKOUT}`,
          payload: {
            index: values.activeWorkoutIndex,
            data: response.data,
          },
        });
      })
      .catch((err) => {
        dispatch({ type: `${namespace}/${UPDATE_WORKOUTS_REJECTED}`, payload: err });
      });
  }
);

export const updateTagWorkout = (namespace, accountCode, values) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${UPDATE_WORKOUTS_START}` });
    axiosWRV.post(`/accounts/${accountCode}/users/${values.userId}/workouts/${values.assignedDate}/items/${values.assignedId}`, values.body, {
      requestId: `${namespace}/updateTagWorkout`,
    })
      .then((response) => {
        dispatch({
          type: `${namespace}/${UPDATE_SUBSTITUTE_WORKOUT}`,
          payload: {
            index: values.index,
            data: response.data.workoutItem,
          },
        });
      })
      .catch((err) => {
        dispatch({ type: `${namespace}/${UPDATE_WORKOUTS_REJECTED}`, payload: err });
      });
  }
);

export const submitPinCode = (namespace, accountCode, userId, values) => (
  (dispatch, getState) => {
    const today = moment().tz(getState().weightRoomView.data.currentUser.userSettings.timeZone);
    dispatch({ type: `${namespace}/${SUBMIT_PIN_START}` });
    axiosWRV.post(`/accounts/${accountCode}/users/${userId}/pin/validate`, values, {
      requestId: `${namespace}/submitPinCode`,
    })
      .then((response) => {
        dispatch({
          type: `${namespace}/${SUBMIT_PIN_FULFILLED}`,
          payload: response.data.result,
        });
        dispatch(selectVerifiedAthlete(namespace));
        dispatch(fetchWorkouts(accountCode, userId, namespace, today));
        dispatch(closePinPad(namespace));
      })
      .catch((err) => {
        dispatch({ type: `${namespace}/${SUBMIT_PIN_REJECTED}`, payload: err });
      });
  }
);

export const selectQuestion = (id, index, namespace) => ({
  type: `${namespace}/${SELECT_QUESTION}`,
  payload: {
    id,
    index,
  },
});

export const restartQuestions = (namespace) => ({
  type: `${namespace}/${RESTART_QUESTIONS}`,
});

export const nextQuestion = (id, index, namespace) => ({
  type: `${namespace}/${NEXT_QUESTION}`,
  payload: {
    id,
    index,
  },
});

export const prevQuestion = (id, index, namespace) => ({
  type: `${namespace}/${PREV_QUESTION}`,
  payload: {
    id,
    index,
  },
});

export const storePanelHeight = (height, width, namespace) => ({
  type: `${namespace}/${STORE_PANEL_HEIGHT}`,
  payload: {
    height,
    width,
  },
});

export const storeFormikValues = (namespace, values) => ({
  type: `${namespace}/${STORE_FORMIK_VALUES}`,
  payload: values,
});

export const storeWorkoutsBySuperset = (namespace, workouts) => ({
  type: `${namespace}/${STORE_WORKOUTS_BY_SUPERSET}`,
  payload: workouts,
});

export const increaseCompletedSets = (namespace, group, workoutIndex) => (
  (dispatch) => new Promise((resolve) => {
    dispatch({
      type: `${namespace}/${INCREASE_COMPLETED_SETS}`,
      payload: {
        group,
        workoutIndex,
      },
    });
    resolve();
  })
);

export const decreaseCompletedSets = (namespace, group, workoutIndex) => (
  (dispatch) => new Promise((resolve) => {
    dispatch({
      type: `${namespace}/${DECREASE_COMPLETED_SETS}`,
      payload: {
        group,
        workoutIndex,
      },
    });
    resolve();
  })
);

export const setIsNavigatingSuperset = (namespace, value) => ({
  type: `${namespace}/${SET_IS_NAVIGATING_SUPERSETS}`,
  payload: value,
});

export const setAdditionalWorkoutsData = (object) => (
  {
    type: SET_ADDITIONAL_WORKOUTS_DATA,
    payload: object,
  }
);

export const clearAdditionalWorkoutsData = () => (
  {
    type: CLEAR_ADDITIONAL_WORKOUTS_DATA,
  }
);

export const setIsAdditionalWorkouts = (bool) => ({
  payload: bool,
  type: SET_IS_ADDITIONAL_WORKOUTS,
});

export const setShowSessionBreakItems = (namespace, index, value) => (
  {
    payload: {
      index,
      value,
    },
    type: `${namespace}/${SET_SHOW_SESSION_BREAK_ITEMS}`,
  }
);

export const setIsCalendarProgramView = (namespace, view) => (
  (dispatch) => {
    if (view === 'program') {
      dispatch({
        type: `${namespace}/${SET_IS_CALENDAR_PROGRAM_VIEW}`,
        payload: {
          calendar: false,
          program: true,
        },
      });
    } else {
      dispatch({
        type: `${namespace}/${SET_IS_CALENDAR_PROGRAM_VIEW}`,
        payload: {
          calendar: true,
          program: false,
        },
      });
      dispatch({ type: `${namespace}/${CLEAR_PROGRAM_DATA}` });
    }
  }
);

export const setIsProgramListModalShowing = (namespace, bool) => ({
  type: `${namespace}/${SET_IS_PROGRAM_LIST_MODAL}`,
  payload: bool,
});

export const setIsResetProgramModalShowing = (namespace, bool) => ({
  type: `${namespace}/${SET_IS_RESET_PROGRAM_MODAL_SHOWING}`,
  payload: bool,
});

export const setIsProgramInfoModalShowing = (namespace, bool) => ({
  type: `${namespace}/${SET_IS_PROGRAM_INFO_MODAL_SHOWING}`,
  payload: bool,
});

export const setIsCompleteProgramModalShowing = (namespace, bool) => ({
  type: `${namespace}/${SET_IS_COMPLETE_PROGRAM_MODAL_SHOWING}`,
  payload: bool,
});

export const setIsCompleteProgramWorkoutModalShowing = (namespace, bool) => ({
  type: `${namespace}/${SET_IS_COMPLETE_PROGRAM_WORKOUT_MODAL_SHOWING}`,
  payload: bool,
});

export const setIsSkipWorkoutModalShowing = (namespace, bool) => ({
  type: `${namespace}/${SET_IS_SKIP_WORKOUT_MODAL_SHOWING}`,
  payload: bool,
});

// Fetch all the programs for an athlete
export const fetchPrograms = (accountCode, id, namespace) => (dispatch) => {
  dispatch({ type: `${namespace}/${FETCH_PROGRAMS_START}` });
  axiosWRV.get(`/accounts/${accountCode}/users/${id}/day-based-workouts`, {
    requestId: `${namespace}/fetchPrograms`,
  })
    .then((response) => {
      dispatch({ type: `${namespace}/${FETCH_PROGRAMS_FULFILLED}`, payload: response.data });
    })
    .catch((err) => {
      dispatch({
        type: `${namespace}/${FETCH_PROGRAMS_REJECTED}`,
        payload: err?.response?.headers?.message
        || 'Unexpexted error fetching programs',
      });
    });
};

// Fetch the next workout session in a givejn program
export const fetchNextWorkoutSession = (accountCode, id, namespace, programId) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${FETCH_NEXT_WORKOUT_SESSION_START}` });
    axiosWRV.get(`/accounts/${accountCode}/users/${id}/day-based-workouts/${programId}/next-session`, {
      requestId: `${namespace}/fetchNextWorkoutSession`,
    })
      .then((response) => {
        dispatch({
          type: `${namespace}/${FETCH_NEXT_WORKOUT_SESSION_FULFILLED}`,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: `${namespace}/${FETCH_NEXT_WORKOUT_SESSION_REJECTED}`,
          payload: err.response.headers.message,
        });
      });
  }
);

// Fetch all the weeks for a given program
export const fetchProgramWeeks = (accountCode, id, namespace, program, programIdx) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${FETCH_PROGRAM_WEEKS_START}` });
    axiosWRV.get(`/accounts/${accountCode}/users/${id}/day-based-workouts/${program.dayBasedProgramId}/weeks`, {
      requestId: `${namespace}/fetchProgramWeeks`,
    })
      .then((response) => {
        setTimeout(() => {
          dispatch({
            type: `${namespace}/${FETCH_PROGRAM_WEEKS_FULFILLED}`,
            payload: {
              data: response.data,
              program: { ...program, programIdx },
            },
          });
        }, [750]);
      })
      .catch((err) => {
        dispatch({
          type: `${namespace}/${FETCH_PROGRAM_WEEKS_REJECTED}`,
          payload: err?.response?.headers?.message
          || 'Unexpected error fetching program weeks',
        });
      });
  }
);

// Fetch one week for a given program
export const fetchProgramWeek = (accountCode, id, namespace, program, weekNum, week) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${FETCH_PROGRAM_WEEK_START}` });
    axiosWRV.get(`/accounts/${accountCode}/users/${id}/day-based-workouts/${program.dayBasedProgramId}/weeks/${weekNum + 1}`, {
      requestId: `${namespace}/fetchProgramWeek`,
    })
      .then((response) => {
        setTimeout(() => {
          dispatch({
            type: `${namespace}/${FETCH_PROGRAM_WEEK_FULFILLED}`,
            payload: {
              data: response.data,
              selectedProgramWeek: week,
            },
          });
        }, [750]);
      })
      .catch((err) => {
        dispatch({
          type: `${namespace}/${FETCH_PROGRAM_WEEK_REJECTED}`,
          payload: err?.response?.headers?.message
          || 'Unexpected error fetching program week',
        });
      });
  }
);

// Fetch the workouts for a given day in a program
export const fetchProgramWorkouts = (
  accountCode, id, namespace, program, dayNum, day, isAdditionalWorkouts = false,
) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${FETCH_PROGRAM_WORKOUTS_START}`, payload: isAdditionalWorkouts });
    axiosWRV.get(`/accounts/${accountCode}/users/${id}/day-based-workouts/${program.dayBasedProgramId}/days/${dayNum}`, {
      requestId: `${namespace}/fetchProgramWorkouts`,
    })
      .then((response) => {
        setTimeout(() => {
          dispatch({
            type: `${namespace}/${FETCH_PROGRAM_WORKOUTS_FULFILLED}`,
            payload: {
              data: response.data,
              day,
            },
          });
        }, 800);

        let workoutsWithSupersets = response.data.workoutItems.filter((workout) => (
          workout.groupingLetter !== null
        ));

        if (workoutsWithSupersets.length > 0) {
          workoutsWithSupersets = workoutsWithSupersets.map((workout) => ({
            ...workout, completed_sets: 0,
          }));

          const workoutsBySupersets = groupBy(workoutsWithSupersets, 'groupingLetter');

          dispatch({
            type: `${namespace}/${STORE_WORKOUTS_BY_SUPERSET}`,
            payload: workoutsBySupersets,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: `${namespace}/${FETCH_PROGRAM_WORKOUTS_REJECTED}`,
          payload: err?.response?.headers?.message
          || 'Unexpected error fetching program workouts',
        });
      });
  }
);

// Reset the current selected program for an athlete
export const resetProgram = (accountCode, id, namespace, program) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${RESET_PROGRAM_START}` });
    axiosWRV.post(`/accounts/${accountCode}/users/${id}/day-based-workouts/${program.dayBasedProgramId}`, {
      requestId: `${namespace}/resetProgram`,
    })
      .then((response) => {
        const responseData = response.data;
        const { programIdx } = program;
        const organizedData = { ...responseData, programIdx };
        dispatch({
          type: `${namespace}/${RESET_PROGRAM_FULFILLED}`,
          payload: organizedData,
        });
      })
      .catch((err) => {
        dispatch({
          type: `${namespace}/${RESET_PROGRAM_REJECTED}`,
          payload: err.response.headers.message,
        });
      });
  }
);

// Complete the current selected program for an athlete
export const completeProgram = (accountCode, id, namespace, program) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${COMPLETE_PROGRAM_START}` });
    axiosWRV.post(`/accounts/${accountCode}/users/${id}/day-based-workouts/${program.dayBasedProgramId}/complete`, {
      requestId: `${namespace}/completeProgram`,
    })
      .then((response) => {
        const responseData = response.data;
        const { programIdx } = program;
        const organizedData = { ...responseData, programIdx };
        dispatch({
          type: `${namespace}/${COMPLETE_PROGRAM_FULFILLED}`,
          payload: organizedData,
        });
      })
      .catch((err) => {
        dispatch({
          type: `${namespace}/${COMPLETE_PROGRAM_REJECTED}`,
          payload: err.response.headers.message,
        });
      });
  }
);

// Skip or complete an athlete's program workout
export const finishProgramWorkout = (
  accountCode, id, namespace, programId, dayNum, completionStatus,
) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${FINISH_PROGRAM_WORKOUT_START}` });
    axiosWRV.put(`/accounts/${accountCode}/users/${id}/day-based-workouts/${programId}/days/${dayNum}/status`, {
      requestId: `${namespace}/finishProgramWorkout`,
      status: completionStatus,
    })
      .then((response) => {
        dispatch({
          type: `${namespace}/${FINISH_PROGRAM_WORKOUT_FULFILLED}`,
          payload: response.data,
        });
        dispatch(fetchNextWorkoutSession(accountCode, id, namespace, programId));
      })
      .catch((err) => {
        dispatch({
          type: `${namespace}/${FINISH_PROGRAM_WORKOUT_REJECTED}`,
          payload: err?.response?.headers?.message
          || 'Unexpected error finishing program workout',
        });
      });
  }
);

// Complete a program workout normally (not skipped or marked complete)
export const updateProgramWorkout = (index, namespace, programId, dayNum, dayIdx, values) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${UPDATE_PROGRAM_WORKOUT_START}` });
    axiosWRV.post(`/accounts/${values.accountCode}/users/${values.userId}/day-based-workouts/${programId}/days/${dayNum}/items/${values.assignedId}`, values.body, {
      requestId: `${namespace}/updateProgramWorkout`,
    })
      .then((response) => {
        setTimeout(() => {
          dispatch({
            type: `${namespace}/${UPDATE_PROGRAM_WORKOUT_FULFILLED}`,
            payload: {
              index,
              data: response.data,
              dayIdx,
            },
          });
          dispatch(
            fetchNextWorkoutSession(values.accountCode, values.userId, namespace, programId),
          );
        }, 800);
      })
      .catch((err) => {
        dispatch({
          type: `${namespace}/${UPDATE_PROGRAM_WORKOUT_REJECTED}`,
          payload: err,
        });
      });
  }
);

// Fetches the program week data when using next session click
export const fetchProgramWeekViaNextSession = (
  accountCode, id, namespace, program, weekNum,
) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${FETCH_PROGRAM_WEEK_VIA_NEXT_SESSION_START}` });
    axiosWRV
      .get(`/accounts/${accountCode}/users/${id}/day-based-workouts/${program.dayBasedProgramId}/weeks/${weekNum}`, {
        requestId: `${namespace}/fetchProgramWeekViaNextSession`,
      })
      .then((response) => {
        setTimeout(() => {
          dispatch({
            type: `${namespace}/${FETCH_PROGRAM_WEEK_VIA_NEXT_SESSION_FULFILLED}`,
            payload: {
              data: response.data,
            },
          });
        }, [750]);
      })
      .catch((err) => {
        dispatch({
          type: `${namespace}/${FETCH_PROGRAM_WEEK_VIA_NEXT_SESSION_REJECTED}`,
          payload:
            err?.response?.headers?.message
            || 'Unexpected error fetching program week',
        });
      });
  }
);

export const optOutProgramWorkout = (namespace, accountCode, programId, dayNum, values) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${UPDATE_PROGRAM_WORKOUT_START}` });
    axiosWRV.post(`/accounts/${accountCode}/users/${values.userId}/day-based-workouts/${programId}/days/${dayNum}/items/${values.workId}/opt-out`, values.body, {
      requestId: `${namespace}/optOutProgramWorkout`,
    })
      .then((response) => {
        dispatch({
          type: `${namespace}/${UPDATE_SUBSTITUTE_PROGRAM_WORKOUT}`,
          payload: {
            index: values.activeWorkoutIndex,
            data: response.data,
          },
        });
      })
      .catch((err) => {
        dispatch({ type: `${namespace}/${UPDATE_PROGRAM_WORKOUT_REJECTED}`, payload: err });
      });
  }
);

export const updateTagProgramWorkout = (namespace, accountCode, programId, dayNum, values) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${UPDATE_PROGRAM_WORKOUT_START}` });
    axiosWRV.post(`/accounts/${accountCode}/users/${values.userId}/day-based-workouts/${programId}/days/${dayNum}/items/${values.assignedId}`, values.body, {
      requestId: `${namespace}/updateTagProgramWorkout`,
    })
      .then((response) => {
        dispatch({
          type: `${namespace}/${UPDATE_SUBSTITUTE_PROGRAM_WORKOUT}`,
          payload: {
            index: values.index,
            data: response.data.workoutItem,
          },
        });
      })
      .catch((err) => {
        dispatch({ type: `${namespace}/${UPDATE_PROGRAM_WORKOUT_REJECTED}`, payload: err });
      });
  }
);

export const fetchImportedIntegrationsByDate = (
  namespace,
  currentUser,
  userId,
  date,
) => (dispatch) => {
  dispatch({ type: `${namespace}/${FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_START}` });
  const { accountCode } = currentUser;

  const route = `/accounts/${accountCode}/integrations/users/${userId}/imports/${date}/summary`;
  axiosWRV
    .get(route)
    .then((response) => {
      dispatch({
        payload: response.data,
        type: `${namespace}/${FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_FULFILLED}`,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err,
        type: `${namespace}/${FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_REJECTED}`,
      });
    });
};

export const fetchSelectedIntegrationByDateWRV = (
  namespace,
  currentUser,
  userId,
  date,
  integration,
  testType,
) => (dispatch) => {
  const { accountCode } = currentUser;
  dispatch({ type: `${namespace}/${FETCH_SELECTED_INTEGRATION_BY_DATE_START}` });

  const route = `/accounts/${accountCode}/integrations/users/${userId}/imports/${integration}/${testType}/${date}/data`;

  axiosWRV
    .get(route)
    .then((response) => {
      dispatch({
        payload: response.data,
        type: `${namespace}/${FETCH_SELECTED_INTEGRATION_BY_DATE_FULFILLED}`,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err,
        type: `${namespace}/${FETCH_SELECTED_INTEGRATION_BY_DATE_REJECTED}`,
      });
    });
};

export const setSelectedIntegration = (namespace, integration) => ({
  payload: integration,
  type: `${namespace}/${SET_SELECTED_INTEGRATION}`,
});

export const setIsIntegrationModalShowing = (namespace, bool) => ({
  type: `${namespace}/${SET_IS_INTEGRATION_MODAL_SHOWING}`,
  payload: bool,
});
